import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

const position = [46.94809, 7.45253];

export default function ContactMap() {
  return (
    <MapContainer
      center={position as any}
      zoom={18}
      style={{ width: "100%", height: "100%", minHeight: "50vh" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
        position={position as any}
        // icon={icon({
        //   iconUrl: image.publicURL,
        //   iconSize: [88, 100], // size of the icon
        //   shadowSize: [50, 64], // size of the shadow
        //   iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
        //   shadowAnchor: [4, 62], // the same for the shadow
        //   popupAnchor: [-3, -76],
        // })}
      >
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker>
    </MapContainer>
  );
}
